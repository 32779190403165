/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://6kge42oxlbgm7gcxfwx3epsdby.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "plaid",
            "endpoint": "https://l1ydl7etfa.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        },
        {
            "name": "transunion",
            "endpoint": "https://55gs8xap21.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        },
        {
            "name": "docusign",
            "endpoint": "https://eqa0k1u714.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        },
        {
            "name": "expenses",
            "endpoint": "https://79rf8dd380.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        },
        {
            "name": "income",
            "endpoint": "https://uscuicksm2.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        },
        {
            "name": "offer",
            "endpoint": "https://58s3xt846a.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        },
        {
            "name": "twilio",
            "endpoint": "https://sbx96krrvc.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        },
        {
            "name": "Cognito",
            "endpoint": "https://xjfi56sdn6.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        },
        {
            "name": "risk",
            "endpoint": "https://cq04130bwh.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        },
        {
            "name": "outreach",
            "endpoint": "https://eri3a6sp6c.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        },
        {
            "name": "partnerproxy",
            "endpoint": "https://o2ohcn0unc.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        },
        {
            "name": "debt",
            "endpoint": "https://kkegqzjvyk.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        },
        {
            "name": "budget",
            "endpoint": "https://zwkrmk5in0.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        },
        {
            "name": "decision",
            "endpoint": "https://520xn7eifb.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:e12260a0-d47b-4f32-97c6-8ed88e52bc52",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_HbDLY1oxY",
    "aws_user_pools_web_client_id": "60kksj112jc4hji12bf4du4ik6",
    "oauth": {}
};


export default awsmobile;
