/* eslint-disable max-len */
import React from 'react';
import { Route } from 'react-router-dom';
import LandingPageLayout from './LandingPageLayout';
import {
  DebtAnalysis, Referral,
} from '../pages';

import {
  CreditGenieFooter,
} from '../components';

const CreditGenieLandingPageLayout = () => {
  const creditGenieRoutes = [
    <Route component={DebtAnalysis} key="debt-analysis-page" path="/debt-analysis" />,
    <Route component={Referral} key="referral-page" path="/referral" />,
  ];

  const creditGenieFooter = <CreditGenieFooter />;

  return <LandingPageLayout environmentSpecificRoutes={creditGenieRoutes} environmentSpecificFooter={creditGenieFooter} />;
};

export default CreditGenieLandingPageLayout;
